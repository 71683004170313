import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAllCustomers({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(`/customers`, {
        params: payload,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getCustomer({ commit, state }, customerId) {
    try {
      const response = await axiosInstance.get(`/customers/${customerId}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
