<template>
  <div>
    <div class="dropdown">
      <button
        class="wb-select-dropdown dropdown-toggle w-100 text-start"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :class="{
          'wb-select-dropdown--plain': dropdownStyle == 'plain',
        }"
      >
        <div class="me-2" :class="[!currency && 'opacity-50']">
          <span
            v-if="currency"
            class="fi me-1"
            :class="['fi-' + currency?.country?.toLowerCase()]"
          ></span>
          {{ currency ? currency.symbol : "Currency" }}
        </div>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul
        class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-start"
      >
        <li v-for="(item, index) in supportedCurrencies" :key="index">
          <a
            class="dropdown-item"
            :class="{
              'dropdown-item--active':
                currency && currency.symbol == item.symbol,
            }"
            href="javascript:void(0)"
            @click="setCurrency(item)"
          >
            <div class="d-flex align-items-center">
              <span
                class="fi me-2 text__body"
                :class="['fi-' + item.country.toLowerCase()]"
              ></span>
              <div>
                <div>
                  {{ item.symbol }}
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencySelect",
  props: {
    dropdownStyle: {
      type: String,
      default: "regular",
    },
    defaultCurrency: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportedCurrencies: [
        {
          name: "Nigerian Naira",
          symbol: "NGN",
          country: "NG",
        },
        {
          name: "US Dollars",
          symbol: "USD",
          country: "US",
        },
      ],
      currency: null,
    };
  },
  created() {
    if (this.defaultCurrency) {
      const defCur = this.supportedCurrencies.find(
        (c) => c.symbol === this.defaultCurrency
      );
      if (defCur) {
        this.currency = defCur;
      }
    }
  },
  methods: {
    setCurrency(currency) {
      if (this.disabled) return;
      this.currency = currency;
      this.$emit("currencySelected", currency);
    },
  },
};
</script>

<style></style>
