import { createVNode, render, ref } from "vue";
import Subsscribe from "@/components/Hooks/Subscribe";

export default function useSubscribe() {
  const isConfirming = ref(false);

  const confirm = (message) => {
    return new Promise((resolve, reject) => {
      const confirmComponent = {
        message,
        onConfirm: (choice) => {
          isConfirming.value = false;
          resolve(choice);
          cleanup();
        },
      };

      const closeConfirmation = () => {
        isConfirming.value = false;
        reject();
        cleanup();
      };

      const cleanup = () => {
        container.remove();
      };
      const container = document.createElement("div");
      document.body.appendChild(container);
      const confirmInstance = createVNode(Subsscribe, confirmComponent);
      render(confirmInstance, container);

      isConfirming.value = true;
    });
  };

  return confirm;
}
