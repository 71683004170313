import { createStore } from "vuex";
import auth from "./modules/auth";
import utils from "./modules/utils";
import theme from "./modules/theme";
import storefront from "./modules/storefront";
import product from "./modules/product";
import order from "./modules/order";
import delivery from "./modules/delivery";
import customer from "./modules/customer";
import createPersistedState from "vuex-plugin-persistedstate";
const store = createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    customer,
    utils,
    theme,
    storefront,
    product,
    order,
    delivery,
  },
});
export default store;
