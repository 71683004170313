import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getStorefrontOrders({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts/${payload?.storeId}/orders`,
        {
          params: payload,
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAllOrders({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(`/orders`, {
        params: payload,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getOrderOverview({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts/${payload?.storeId}/orders/overview`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
