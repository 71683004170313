<template>
  <div class="dropdown-center">
    <button
      class="wb-select-dropdown dropdown-toggle w-100 text-start text-capitalize"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="[isSmall && 'wb-select-dropdown--small text__body--smaller']"
    >
      {{ selectedItem ? selectedItem.label : placeholder }}
      <i class="bi bi-chevron-down ms-2"></i>
    </button>
    <ul
      class="dropdown-menu wb-dropdown-menu wb-dropdown-menu--form dropdown-menu-end py-3 px-2 w-100"
    >
      <li v-for="item in items" :key="item.value">
        <a
          @click="setItem(item)"
          href="javascript:void(0)"
          class="dropdown-item"
          :class="[isSmall && 'wb-select-dropdown--small text__body--smaller']"
          >{{ item.label }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  placeholder: {
    type: String,
    default: "Choose One",
  },
  items: {
    type: Array,
    required: true,
  },
  selected: {
    type: Object,
    required: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["itemSelected"]);
const selectedItem = ref(props.selected || null);
const setItem = (item) => {
  selectedItem.value = item;
  emit("itemSelected", item);
};
</script>

<style scoped></style>
